<template>
  <div>
    <el-dialog
      v-dialogDrag
      title="新增现货采购"
      :close-on-click-modal="false"
      :visible.sync="dialogVisible"
      :before-close="handleClose"
      width="900px"
    >
      <div>
        <el-form ref="form" :rules="rules" :model="form" label-position="left">
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item
                label="商品分类"
                prop="commodityId"
                label-width="120px"
              >
                <el-cascader
                  class="width-100"
                  v-model="form.commodityId"
                  :options="categoryOptions"
                  :props="categoryProps"
                  :show-all-levels="false"
                  ref="cascader"
                ></el-cascader>
              </el-form-item>
            </el-col>
            <el-col
              :span="12"
              v-for="(item, index) of gradeIdOptions"
              :key="index"
            >
              <el-form-item
                :label="item.name"
                prop="specificationsName"
                label-width="120px"
              >
                <el-select
                  class="width-100"
                  v-model="form.specificationsName[index]"
                >
                  <el-option
                    v-for="items in item.children"
                    :key="items.id"
                    :label="items.value"
                    :value="item.value + ',' + items.value"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="仓库" prop="storageId" label-width="120px">
                <el-select
                  class="width-100"
                  v-model="form.storageId"
                  @change="unitChange"
                >
                  <el-option
                    v-for="item in storageIdOptions"
                    :key="item.storageId"
                    :label="item.name"
                    :value="item.storageId"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="数量" prop="quantity" label-width="120px">
                <el-input v-model.number="form.quantity"></el-input>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item label="单价" prop="price" label-width="120px">
                <el-input v-model="form.price"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="总价" prop="zjPriceCalc" label-width="120px">
                <el-input v-model.number="zjPriceCalc" disabled></el-input>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item label="重量" prop="weight" label-width="120px">
                <el-input v-model="form.weight"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item
                label="重量单位"
                prop="weightUnitId"
                label-width="120px"
              >
                <el-select
                  class="width-100"
                  v-model="form.weightUnitId"
                  placeholder="请先选择仓库，再选择重量单位
"
                >
                  <el-option
                    v-for="items in weightUnitOptions"
                    :key="items.weightUnitId"
                    :label="items.name"
                    :value="items.weightUnitId"
                  ></el-option>
                </el-select>
                <!-- <el-input v-model="form.unit"></el-input> -->
              </el-form-item>
            </el-col>
            <!-- <el-col :span="12">
              <el-form-item label="生产厂家" prop="manufacturer" label-width="120px">
                <el-input v-model="form.manufacturer"></el-input>
              </el-form-item>
            </el-col>-->

            <el-col :span="12">
              <el-form-item
                label="预计入库时间"
                prop="putWarehousTime"
                label-width="120px"
              >
                <el-date-picker
                  class="width-100"
                  v-model="form.putWarehousTime"
                  type="date"
                  default-time="17:30:00"
                  :picker-options="putWarehousTimePicker"
                ></el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item
                label="开票期限"
                prop="openTicketTime"
                label-width="120px"
              >
                <el-date-picker
                  class="width-100"
                  v-model="form.openTicketTime"
                  type="date"
                  :picker-options="putWarehousTimePicker"
                ></el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item
                label="挂单有效时间"
                prop="effectiveTime"
                label-width="120px"
              >
                <el-date-picker
                  class="width-100"
                  v-model="form.effectiveTime"
                  type="date"
                  :picker-options="putWarehousTimePicker"
                ></el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item
                label="是否接受议价"
                prop="bargainingStatus"
                label-width="120px"
              >
                <el-radio-group v-model="form.bargainingStatus" size="small">
                  <el-radio label="T">接受</el-radio>
                  <el-radio label="F">不接受</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item
                label="业务负责人"
                prop="businessName"
                label-width="120px"
              >
                <el-input v-model="form.businessName"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item
                label="联系电话"
                prop="businessPhone"
                label-width="120px"
              >
                <el-input v-model="form.businessPhone"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item
                label="交易方式"
                prop="tradeWay"
                label-width="120px"
              >
                <el-radio-group v-model="form.tradeWay" size="small">
                  <el-radio label="A">整批</el-radio>
                  <el-radio label="P">分销</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
            <el-col :span="12" v-if="form.tradeWay == 'P'">
              <el-form-item
                label="最小起订量"
                prop="minPickWeight"
                label-width="100px"
              >
                <el-input v-model="form.minPickWeight"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12" v-if="form.tradeWay == 'P'">
              <el-form-item
                label="是否集采分销"
                prop="phaseType"
                label-width="120px"
              >
                <el-radio-group v-model="form.phaseType" size="small">
                  <el-radio label="T">使用</el-radio>
                  <el-radio label="F">不使用</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
            <el-col
              :span="12"
              v-if="form.phaseType == 'T' && form.tradeWay == 'P'"
            >
              <el-form-item
                label="集采分销结束时间"
                prop="phaseEndTime"
                label-width="150px"
              >
                <el-date-picker
                  class="width-100"
                  v-model="form.phaseEndTime"
                  type="date"
                  :picker-options="putWarehousTimePicker"
                ></el-date-picker>
              </el-form-item>
            </el-col>
            <!-- <el-col :span="12">
              <el-form-item label="商品图片" prop="image" label-width="120px">
                <el-upload
                  class="avatar-uploader"
                  :action="actionUrl"
                  :show-file-list="false"
                  :on-success="handleAvatarSuccess"
                  :before-upload="beforeAvatarUpload"
                  :data="postData"
                >
                  <img v-if="form.image" :src="form.image" class="avatar" />
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
              </el-form-item>
            </el-col>-->
          </el-row>
          <el-row v-if="form.phaseType == 'T' && form.tradeWay == 'P'">
            <el-col
              :span="24"
              class="stepPriceCon"
              v-if="stepPriceList.length != 0"
            >
              <span
                v-for="(item, index) of stepPriceList"
                :key="index"
                @click="centerDialogVisible = true"
                >{{ item.lowWeight }}< X{{ index + 1 }} ≤{{ item.highWeight
                }}{{ selectWeightUnit.name }} &nbsp; {{ item.price }}元/{{
                  selectWeightUnit.name
                }}&nbsp;&nbsp;&nbsp;&nbsp;</span
              >
            </el-col>
            <el-col :span="12">
              <div
                class="stepPriceBtn"
                v-if="right.addStepPrice && stepPriceList.length == 0"
                @click="isaddStepPrices"
              >
                添加阶梯
              </div>
            </el-col>
          </el-row>
          <el-row
            :gutter="20"
            v-if="form.phaseType == 'T' && form.tradeWay == 'P'"
          >
            <el-col :span="12">
              <el-form-item
                label="集采订单重量"
                prop="phaseWeight"
                label-width="120px"
              >
                <el-input v-model="form.phaseWeight"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-col :span="24">
            <el-form-item
              label="特殊要求描述"
              prop="manufacturer"
              label-width="120px"
            >
              <el-input
                v-model="form.manufacturer"
                placeholder="请您将生产厂家、产地、品牌及其他相关特殊要求写在此处"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="confirm()">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      v-dialogDrag
      title="添加阶梯价"
      :visible.sync="centerDialogVisible"
      width="600px"
      center
    >
      <el-form ref="stepForm">
        <div class="tips">温馨提示：重量区间前开后闭</div>
        <!-- <el-form-item label="重量区间" label-width="120px">
          <el-col :span="11">
            <el-input v-model="lowWeight">
              <template slot="append">{{selectWeightUnit.name }}</template>
            </el-input>
          </el-col>
          <el-col class="line" :span="2">-</el-col>
          <el-col :span="11">
            <el-input v-model="hightWeight">
              <template slot="append">{{selectWeightUnit.name }}</template>
            </el-input>
          </el-col>
        </el-form-item>
        <el-form-item label="价格" prop="stepPrice" label-width="120px">
          <el-input v-model="stepPrice">
            <template slot="append">元</template>
          </el-input>
        </el-form-item> -->
        <div v-for="(item, indexs) of setAllPriceList" :key="indexs">
          <el-form-item label="重量区间" label-width="100px">
            <el-col :span="10" style="padding:0">
              <el-input v-model="item.lowWeight" disabled>
                <template slot="append">{{ selectWeightUnit.name }}</template>
              </el-input>
            </el-col>
            <el-col class="line" :span="4">< X{{ indexs + 1 }} ≤</el-col>
            <el-col :span="10" style="padding:0">
              <el-input
                v-model="item.highWeight"
                oninput="value=value.replace(/^(\-)*(\d+)\.(\d{0,2}).*$/g,'$1$2.$3')"
                @change="linerInput(item.lowWeight, item.highWeight, indexs)"
              >
                <template slot="append">{{ selectWeightUnit.name }}</template>
              </el-input>
            </el-col>
          </el-form-item>
          <el-form-item label="价格" prop="stepPrice" label-width="100px">
            <el-input
              v-model="item.price"
              oninput="value=value.replace(/^(\-)*(\d+)\.(\d{0,2}).*$/g,'$1$2.$3')"
            >
              <template slot="append">元/{{ selectWeightUnit.name }}</template>
            </el-input>
          </el-form-item>
        </div>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addPrice()">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      id="d1"
      v-dialogDrag
      title="温馨提示"
      :close-on-click-modal="false"
      :visible.sync="dialogVisible1"
      width="30%"
      :before-close="handledialogVisible1"
    >
      <el-form ref="newForm">
        <el-row :gutter="20">
          <el-col
            :span="24"
            style="color: red;font-weight: 700;font-size: 18px"
          >
            本委托须冻结履约履约订金: ￥{{ deposit }}
            <br />请联系财务人员或企业管理员进行支付操作！
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible1 = false">取 消</el-button>
        <el-button type="primary" @click="confirmSure()" :loading="isAddLoading"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    var checkAmount = (rule, value, callback) => {
      let reg = /^[1-9]\d*$/g;
      if (value) {
        if (!reg.test(value)) {
          return callback(new Error("请输入正整数"));
        } else {
          callback();
        }
      } else {
        callback();
      }
      // if (value == "") {
      //   callback(new Error("请输入数量"));
      // } else if (!reg.test(value)) {
      //   return callback(new Error("请输入正整数"));
      // } else {
      //   callback();
      // }
    };
    var checkLowWight = (rule, value, callback) => {
      let reg = /^[1-9]\d*$/g;
      if (!reg.test(value)) {
        return callback(new Error("请输入正整数"));
      } else {
        callback();
      }
    };
    var checkheightWight = (rule, value, callback) => {
      let reg = /^[1-9]\d*$/g;
      if (!this.stepForm.lowWeight) {
        callback(new Error("请输入区间最低重量"));
      } else if (value == "") {
        callback(new Error("请输入区间最高重量"));
      } else if (!reg.test(value)) {
        return callback(new Error("请输入正整数"));
      } else if (this.stepForm.lowWeight >= value) {
        callback(new Error("最低重量应小于最高重量"));
      } else {
        callback();
      }
    };
    var checkPutWarehousDay = (rule, value, callback) => {
      const that = this;
      if (that.form.effectiveTime) {
        let currentTime = that.form.effectiveTime.getTime();
        let newValue = value.getTime();
        console.log(currentTime);
        console.log(newValue);
        if (newValue <= currentTime) {
          return callback(new Error("预计入库时间应晚于挂单有效时间"));
        } else {
          callback();
        }
      }
      // let currentTime = new Date();
      // let yearTime = currentTime.getFullYear();
      // let monTime = currentTime.getMonth() + 1;
      // let dayTime = currentTime.getDate();
      // if (monTime < 10) {
      //   monTime = "0" + monTime;
      // }
      // if (dayTime < 10) {
      //   dayTime = "0" + dayTime;
      // }
      // let str = `${yearTime}${monTime}${dayTime}`;
      // if (value == "") {
      //   callback(new Error("请选择入库时间"));
      // } else if (Number(value) < Number(str)) {
      //   return callback(new Error("入库时间只能选择今天及以后"));
      // } else {
      //   callback();
      // }
    };
    var checkOpenTicketDay = (rule, value, callback) => {
      const that = this;
      if (!that.form.putWarehousTime) {
        callback(new Error("请先选择入库时间"));
      } else if (value == "") {
        callback(new Error("请选择开票时间"));
      } else if (that.form.putWarehousTime) {
        let currentTime = that.form.putWarehousTime.getTime();
        let newValue = value.getTime();
        if (newValue <= currentTime) {
          return callback(new Error("开票时间应晚于预计入库时间"));
        } else if (that.form.phaseType == "T") {
          if (!that.form.phaseEndTime) {
            return callback(new Error("请选择集采分销结束时间"));
          } else if (newValue <= that.form.phaseEndTime.getTime()) {
            return callback(new Error("开票时间应晚于集采分销结束时间"));
          } else {
            callback();
          }
        } else {
          callback();
        }
      }
    };
    var checkeffectiveTime = (rule, value, callback) => {
      const that = this;
      if (value == "") {
        callback(new Error("请选择挂单有效时间"));
      } else if (!that.form.openTicketTime) {
        return callback(new Error("请选择开票期限"));
      } else if (value.getTime() >= that.form.openTicketTime.getTime()) {
        return callback(new Error("挂单有效时间应早于开票期限"));
      } else if (that.form.phaseType == "T") {
        let newValue = value.getTime();
        if (!that.form.phaseEndTime) {
          return callback(new Error("请选择集采分销结束时间"));
        } else if (newValue < that.form.phaseEndTime.getTime()) {
          return callback(
            new Error("挂单有效时间应晚于或等于集采分销结束时间")
          );
        } else {
          callback();
        }
      } else {
        callback();
      }
    };
    var checkSpecName = (rule, value, callback) => {
      if (
        this.gradeIdOptions.length != 0 &&
        value.length != this.gradeIdOptions.length
      ) {
        return callback(new Error("请选择商品属性"));
      } else {
        callback();
      }
    };
    var checkPhaseEndTime = (rule, value, callback) => {
      if (this.form.phaseType == "T" && !value) {
        return callback(new Error("请选择集采分销结束时间"));
      } else {
        callback();
      }
    };
    var checkPhaseType = (rule, value, callback) => {
      if (this.form.tradeWay == "P" && !value) {
        return callback(new Error("请选择是否使用集采分销"));
      } else {
        callback();
      }
    };
    var checkPhaseWeight = (rule, value, callback) => {
      let reg = /^\d+(\.\d{0,2})?$/;
      if (this.form.phaseType == "T" && !value) {
        return callback(new Error("请输入集采分销达到重量"));
      } else if (
        this.form.phaseType == "T" &&
        (!reg.test(value) || Number(value) <= 0)
      ) {
        return callback(new Error("单价必须是正数且不能超过两位小数"));
      } else if (
        this.form.phaseType == "T" &&
        Number(value) > Number(this.form.weight)
      ) {
        return callback(new Error("请集采分销重量不能大于挂单重量"));
      } else if (
        this.form.phaseType == "T" &&
        Number(value) < Number(this.form.minPickWeight)
      ) {
        return callback(new Error("请集采分销重量不能小于最小起订量"));
      } else {
        callback();
      }
    };
    var checkPutHoldWeight = (rule, value, callback) => {
      let reg = /^\d+(\.\d{0,2})?$/;
      if (value == "") {
        return callback(new Error("请输入重量"));
      } else if (!reg.test(value) || Number(value) <= 0) {
        return callback(new Error("重量必须是正数且不能超过两位小数"));
      } else {
        callback();
      }
    };
    var checkMinPickWeight = (rule, value, callback) => {
      let reg = /^\d+(\.\d{0,2})?$/;
      if (this.form.tradeWay == "P" && !value) {
        return callback(new Error("请输入最小起订量"));
      } else if (
        this.form.tradeWay == "P" &&
        (!reg.test(value) || Number(value) <= 0)
      ) {
        return callback(new Error("最小起订量必须是正数且不能超过两位小数"));
      } else if (
        this.form.tradeWay == "P" &&
        Number(value) > Number(this.form.weight)
      ) {
        return callback(new Error("最小起订量不能大于挂单重量"));
      } else {
        callback();
      }
    };

    var checkPrice = (rule, value, callback) => {
      let reg = /^\d+(\.\d{0,2})?$/;
      if (value == "") {
        return callback(new Error("请输入单价"));
      } else if (!reg.test(value) || Number(value) <= 0) {
        return callback(new Error("单价必须是正数且不能超过两位小数"));
      } else {
        callback();
      }
    };
    return {
      dialogVisible1: false,
      dialogVisible: false,
      centerDialogVisible: false,
      gradeName: null,
      lowWeight: null,
      hightWeight: null,
      stepPrice: null,
      stepPriceList: [],
      right: {
        addStepPrice: false
      },
      form: {
        bargainingStatus: null, //是否可以议价T：可以F：不可以
        businessName: null, //业务负责人
        businessPhone: null, // 电话
        commodityId: null, // 商品id
        specificationsName: [], //规格名称{颜色：红色}json形式
        manufacturer: null, //厂家
        openTicketTime: null, // 开票期限
        phaseEndTime: null, //集采分销结束时间
        phaseType: null, //是否开启集采分销T：开F：不开
        phaseWeight: null, //集采分销达到重量
        price: null, //单价
        quantity: null, //挂单数量
        sourceId: null, //来源W：现货J：竞价C:预售
        tradeWay: null, // A：整批P：分销
        weight: null, // 挂单重量
        weightUnitId: null, // 重量单位
        putWarehousTime: null,
        image: null,
        storageId: null,
        effectiveTime: null,
        minPickWeight: null
      },
      setAllPriceList: [{ lowWeight: "0", highWeight: "", price: "" }],
      newForm: {
        code: null,
        marginPassWord: null
      },
      weightUnitOptions: [],
      selectWeightUnit: {},
      steprules: {
        lowWeight: [
          { required: true, message: "请输入区间最低重量", trigger: "change" },
          { validator: checkLowWight, trigger: "blur" }
        ],
        hightWeight: [
          { required: true, message: "请输入区间最高重量", trigger: "change" },
          { validator: checkheightWight, trigger: "blur" }
        ]
      },
      rules: {
        commodityId: [
          { required: true, message: "请选择商品分类", trigger: "blur" }
        ],
        specificationsName: [
          { required: false, message: "请选择商品属性", trigger: "change" },
          { validator: checkSpecName, trigger: "blur" }
        ],
        bargainingStatus: [
          { required: true, message: "请选择是否可议价", trigger: "change" }
        ],
        openTicketTime: [
          { required: true, message: "请选择开票期限", trigger: "change" },
          { validator: checkOpenTicketDay, trigger: "change" }
        ],
        putWarehousTime: [
          { required: true, message: "请选择预计入库时间", trigger: "change" },
          { validator: checkPutWarehousDay, trigger: "change" }
        ],
        effectiveTime: [
          { required: true, message: "请选择挂单有效时间", trigger: "change" },
          { validator: checkeffectiveTime, trigger: "change" }
        ],
        price: [
          { required: true, message: "请输入价格", trigger: "change" },
          { validator: checkPrice, trigger: "change" }
        ],
        quantity: [
          { required: false, message: "请输入数量", trigger: "change" },
          { validator: checkAmount, trigger: "blur" }
        ],
        phaseEndTime: [
          {
            required: false,
            message: "请选择集采分销结束时间",
            trigger: "change"
          },
          { validator: checkPhaseEndTime, trigger: "blur" }
        ],
        phaseType: [
          {
            required: false,
            message: "请选择是否使用集采分销",
            trigger: "change"
          },
          { validator: checkPhaseType, trigger: "blur" }
        ],
        phaseWeight: [
          { required: false, message: "请输入集采分销重量", trigger: "change" },
          { validator: checkPhaseWeight, trigger: "blur" }
        ],
        weight: [
          { required: true, message: "请输入重量", trigger: "change" },
          {
            validator: checkPutHoldWeight,
            trigger: "change"
          }
        ],
        weightUnitId: [
          { required: true, message: "请选择重量单位", trigger: "blur" }
        ],
        // image: [
        //   { required: true, message: "请上传图片", trigger: "change" }
        // ],
        manufacturer: [
          { required: true, message: "请输入特殊要求描述", trigger: "change" }
        ],
        storageId: [
          { required: true, message: "请选择仓库", trigger: "change" }
        ],
        tradeWay: [
          { required: true, message: "请选择交易方式", trigger: "change" }
        ],
        minPickWeight: [
          { required: true, message: "请输入最小起订量", trigger: "change" },
          {
            validator: checkMinPickWeight,
            trigger: "change"
          }
        ],
        businessName: [
          { required: true, message: "请输入业务负责人", trigger: "change" }
        ],
        businessPhone: [
          { required: true, message: "请输入联系电话", trigger: "change" }
          // {
          //   pattern: /^1[3456789]\d{9}$/,
          //   message: "手机号格式不正确",
          //   trigger: "blur"
          // }
        ]
      },
      newFormRules: {
        // code: [{ required: true, message: "请输入短信验证码", trigger: "change" }],
        // marginPassWord: [{ required: true, message: "请输入资金密码", trigger: "change" }],
      },
      putWarehousTimePicker: {
        disabledDate: time => {
          return time.getTime() < Date.now() - 8.64e7;
        }
      },
      effectiveTimePicker: {
        disabledDate: time => {
          return time.getTime() < Date.now();
        }
      },
      categoryProps: {
        label: "className",
        value: "classId"
      },
      gradeIdProps: {
        label: "value",
        value: "value"
      },
      categoryOptions: [],
      gradeIdOptions: [],
      packagIdOptions: [],
      specificationsIdOptions: [],
      storageIdOptions: [],
      // zjPriceCalc: null,
      isAddLoading: false,
      actionUrl: window.g.APP_USER_PATH_UPLOAD,
      postData: {
        uid: this.$store.state.common.sessionInfo.firmId,
        deleteStatus: 0,
        width: null,
        height: null,
        size: null
      },
      validButtonText: "发送验证码",
      validBtnDisabled: false,
      maxWeight: null,
      newCommodityId: null
    };
  },
  watch: {
    "form.commodityId": {
      handler(val, oldVal) {
        this.gradeIdOptions = [];
        this.form.specificationsName = [];
        this.getGradeIdOptions();
        this.setAllPriceList = [{ lowWeight: "0", highWeight: "", price: "" }];
        this.stepPriceList = [];
      }
    },
    "form.weightUnitId": {
      handler(val, oldVal) {
        let that = this;
        this.setAllPriceList = [{ lowWeight: "0", highWeight: "", price: "" }];
        this.stepPriceList = [];
        for (let i = 0; i < this.weightUnitOptions.length; i++) {
          if (val == that.weightUnitOptions[i].weightUnitId) {
            that.selectWeightUnit = that.weightUnitOptions[i];
          }
        }
      }
    }
  },
  computed: {
    ...mapGetters(["getMarginRateGetter"]),
    deposit() {
      let currentPrice = util.floatMul(this.form.price, this.form.weight);
      return util.floatMul(currentPrice, this.getMarginRateGetter.marginRate);
    },
    zjPriceCalc() {
      return util.floatMul(this.form.price, this.form.weight);
    }
  },
  methods: {
    // 当前选中仓库
    unitChange(e) {
      this.weightUnitOptions = [];
      this.form.weightUnitId = "";
      protocolFwd.param_unitOrWarehouse.param.storageId = e;
      http.postFront(protocolFwd.param_unitOrWarehouse).then(response => {
        console.log(response);
        let { value, code, message } = response.data;
        if (code == 0) {
          this.weightUnitOptions = value.content;
        }
      });
    },
    handledialogVisible1() {
      this.dialogVisible1 = false;
    },
    showDialog() {
      const _cascader = this.$refs.cascader;
      if (_cascader) {
        _cascader.$refs.panel.checkedValue = [];
        _cascader.$refs.panel.activePath = [];
        _cascader.$refs.panel.syncActivePath();
      }
      this.dialogVisible = true;
      this.getCommodityCategory();
      this.getfindWeightUnit();
      this.getRights();
      this.getStorageIdOptions();
    },
    getRights() {
      this.loadRight().then(loadResult => {
        if (loadResult == "loadRightSuccess") {
          this.right.addStepPrice = this.isHasRight(
            "tc-trade-setPhaseHangResourcePrice"
          );
        }
      });
    },
    getfindWeightUnit() {
      http.postFront(protocolFwd.param_findWeightUnit).then(response => {
        const { code, message, value } = response.data;
        if (code == 0) {
          // this.weightUnitOptions = value.content;
        } else {
          this.$EL_MESSAGE(message);
        }
      });
    },
    linerInput(lowValue, value, indexs) {
      if (indexs == this.setAllPriceList.length - 1) {
        if (Number(value) > Number(this.form.weight)) {
          return this.$EL_MESSAGE("重量区间最大值不能大于挂单重量");
        }
        if (Number(value) <= Number(lowValue)) {
          return this.$EL_MESSAGE("重量区间最大值应大于最小值");
        } else if (Number(value) < Number(this.form.weight)) {
          let obj = {};
          obj.lowWeight = value;
          obj.highWeight = "";
          obj.price = "";
          this.setAllPriceList.push(obj);
        }
      } else {
        this.setAllPriceList[indexs + 1].lowWeight = value;
        if (Number(value) == Number(this.form.weight)) {
          this.setAllPriceList.splice(indexs + 1);
        } else if (Number(value) <= Number(lowValue)) {
          this.setAllPriceList.splice(indexs + 1);
          return this.$EL_MESSAGE("重量区间最大值应大于最小值");
        } else if (Number(value) > Number(this.form.weight)) {
          this.setAllPriceList.splice(indexs + 1);
          return this.$EL_MESSAGE("重量区间最大值不能大于挂单重量");
        }
      }
    },
    isaddStepPrices() {
      if (this.form.commodityId && this.form.weight && this.form.weightUnitId) {
        this.setAllPriceList = [{ lowWeight: "0", highWeight: "", price: "" }];
        this.stepPriceList = [];
        this.centerDialogVisible = true;
      } else {
        this.$EL_MESSAGE("请选择商品分类或输入重量或选择重量单位");
      }
    },
    addPrice() {
      const that = this;
      let len = this.setAllPriceList.length;
      if (len == 0) {
        return this.$EL_MESSAGE("请输入阶梯区间");
      }
      for (let i = 0; i < len; i++) {
        if (
          !this.setAllPriceList[i].price ||
          this.setAllPriceList[i].lowWeight == undefined ||
          this.setAllPriceList[i].lowWeight == null ||
          !this.setAllPriceList[i].highWeight
        ) {
          return this.$EL_MESSAGE("阶梯数据不能为空");
        }
      }
      if (
        Number(this.setAllPriceList[len - 1].highWeight) >
        Number(this.form.weight)
      ) {
        return this.$EL_MESSAGE("最大区间重量不能大于挂单重量");
      }
      if (
        Number(this.setAllPriceList[len - 1].lowWeight) ==
        Number(this.setAllPriceList[len - 1].highWeight)
      ) {
        return this.$EL_MESSAGE("重量区间最大值应大于最小值");
      }
      // let obj = {};
      // obj.lowWeight = this.lowWeight;
      // obj.highWeight = this.highWeight;
      // obj.price = this.stepPrice;
      this.stepPriceList = this.setAllPriceList;
      this.centerDialogVisible = false;
    },
    setTime(e, vueThis) {
      let _this = this;
      e--;
      vueThis.validButtonText = e + "s";
      if (e > 0) {
        setTimeout(function() {
          _this.setTime(e, vueThis);
        }, 1000);
      } else {
        vueThis.validButtonText = "发送验证码";
        vueThis.validBtnDisabled = false;
      }
    },
    modifyStepPrice(item, index) {
      // 修改阶梯价
      this.lowWeight = item.lowWeight;
      this.hightWeight = item.highWeight;
      this.stepPrice = item.price;
      this.centerDialogVisible = true;
      this.stepPriceList.splice(index, 1);
    },
    sureStepPrice(value) {
      protocolFwd.param_setPhaseHangResourcePrice.param.hangResourcesId = value;
      protocolFwd.param_setPhaseHangResourcePrice.param.obj = this.stepPriceList;
      http
        .postFront(protocolFwd.param_setPhaseHangResourcePrice)
        .then(response => {
          this.$EL_MESSAGE(response.data.message);
          this.dialogVisible = false;
          this.dialogVisible1 = false;
          this.$EL_MESSAGE("提交成功！待后台审核成功后方可发布");
          this.$refs["form"].resetFields();
          this.$emit("reLoad");
        });
    },
    sendValidNo() {
      protocol.param_authn_margin_sendCaptcha.param.id = 30;
      http.postFront(protocol.param_authn_margin_sendCaptcha).then(response => {
        this.$EL_MESSAGE(response.data.message);
        const that = this;
        this.validBtnDisabled = true;
        this.setTime(60, that);
      });
    },
    getCommodityCategory() {
      http.getRes(protocolFwd.param_queryVarieties).then(response => {
        const { code, message, value } = response.data;
        if (code == 0) {
          this.deleteCategoryOptionsLast(value);
          this.categoryOptions = value;
        } else {
          this.$EL_MESSAGE(message);
        }
      });
    },
    deleteCategoryOptionsLast(data) {
      for (const item of data) {
        if (item.children.length !== 0) {
          this.deleteCategoryOptionsLast(item.children);
        } else {
          delete item.children;
        }
      }
    },
    getGradeIdOptions() {
      if (Array.isArray(this.form.commodityId)) {
        this.newCommodityId = this.form.commodityId[
          this.form.commodityId.length - 1
        ];
      } else {
        this.newCommodityId = this.form.commodityId;
      }
      protocolFwd.param_queryTemplateGroup.param.goodsGroupClassId = this.newCommodityId;
      http.postFront(protocolFwd.param_queryTemplateGroup).then(response => {
        const { code, message, value } = response.data;
        if (code == 0) {
          if (value.length != 0) {
            let goodsGroupCommonSpec = JSON.parse(value[0].paramTemplate);
            for (let i = 0; i < goodsGroupCommonSpec.length; i++) {
              goodsGroupCommonSpec[i].id = value[0].id;
              goodsGroupCommonSpec[i].value = goodsGroupCommonSpec[i].name;
              goodsGroupCommonSpec[i].children =
                goodsGroupCommonSpec[i].content;
            }
            this.gradeIdOptions = goodsGroupCommonSpec;
          } else {
            this.gradeIdOptions = [];
          }
        } else {
          this.$EL_MESSAGE(message);
        }
      });
    },
    getStorageIdOptions() {
      http.postFront(protocolFwd.param_getStorage).then(response => {
        const { code, message, value } = response.data;
        if (code == 0) {
          this.storageIdOptions = value;
        } else {
          this.$EL_MESSAGE(message);
        }
      });
    },
    calcWeight() {
      let specificationsValue = null;
      for (let i = 0; i < this.specificationsIdOptions.length; i++) {
        if (
          this.form.specificationsId ==
          this.specificationsIdOptions[i].specificationsId
        ) {
          specificationsValue = parseFloat(
            this.specificationsIdOptions[i].value
          );
        }
      }
      this.form.weight = util.floatMul(this.form.quantity, specificationsValue);
    },
    calcZjPrice() {
      this.zjPriceCalc = util.floatMul(this.form.price, this.form.weight);
    },
    handleAvatarSuccess(res, file) {
      this.form.image = res.value;
    },
    beforeAvatarUpload(file) {
      const testmsg = /^image\/(jpeg|png|jpg)$/.test(file.type);
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!testmsg) {
        this.$EL_MESSAGE.error("上传图片格式不对!");
        return;
      }
      if (!isLt2M) {
        this.$EL_MESSAGE.error("上传图片大小不能超过 2MB!");
      }
      return testmsg && isLt2M;
    },
    confirm() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          this.dialogVisible1 = true;
        }
      });
    },
    confirmSure() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          let specificationsNames = {}; // 对象
          if (this.form.specificationsName.length != 0) {
            for (let i = 0; i < this.form.specificationsName.length; i++) {
              let currentObj = {};
              let str = this.form.specificationsName[i];
              let str1 = str.split(",");
              currentObj[str1[0]] = str1[1];
              specificationsNames = Object.assign(
                specificationsNames,
                currentObj
              );
            }
          }

          protocolFwd.param_hangBuyPresaleResources.param.bargainingStatus = this.form.bargainingStatus;
          protocolFwd.param_hangBuyPresaleResources.param.commodityId = this.newCommodityId;
          protocolFwd.param_hangBuyPresaleResources.param.specificationsName = specificationsNames
            ? specificationsNames
            : null;
          protocolFwd.param_hangBuyPresaleResources.param.openTicketTime = this.form.openTicketTime;
          protocolFwd.param_hangBuyPresaleResources.param.putWarehousTime = this.form.putWarehousTime;
          protocolFwd.param_hangBuyPresaleResources.param.effectiveTime =
            this.form.effectiveTime.getTime() + 60900000;

          protocolFwd.param_hangBuyPresaleResources.param.quantity = this.form.quantity;
          protocolFwd.param_hangBuyPresaleResources.param.price = this.form.price;
          protocolFwd.param_hangBuyPresaleResources.param.tradeWay = this.form.tradeWay;
          protocolFwd.param_hangBuyPresaleResources.param.weight = this.form.weight;
          protocolFwd.param_hangBuyPresaleResources.param.manufacturer = this.form.manufacturer;
          protocolFwd.param_hangBuyPresaleResources.param.businessName = this.form.businessName;
          protocolFwd.param_hangBuyPresaleResources.param.businessPhone = this.form.businessPhone;
          protocolFwd.param_hangBuyPresaleResources.param.storageId = this.form.storageId;
          protocolFwd.param_hangBuyPresaleResources.param.sourceId = "W";
          // protocolFwd.param_hangBuyPresaleResources.param.image = this.form.image;
          protocolFwd.param_hangBuyPresaleResources.param.weightUnitId = this.form.weightUnitId;
          if (this.form.tradeWay == "P") {
            protocolFwd.param_hangBuyPresaleResources.param.phaseType = this.form.phaseType;
            protocolFwd.param_hangBuyPresaleResources.param.phaseWeight = this.form.phaseWeight;
            protocolFwd.param_hangBuyPresaleResources.param.minPickWeight = this.form.minPickWeight;
            if (this.form.phaseType == "T") {
              protocolFwd.param_hangBuyPresaleResources.param.phaseEndTime =
                this.form.phaseEndTime.getTime() + 60600000;
            } else {
              protocolFwd.param_hangBuyPresaleResources.param.phaseEndTime = null;
            }
          } else {
            protocolFwd.param_hangBuyPresaleResources.param.phaseType = "F";
            protocolFwd.param_hangBuyPresaleResources.param.phaseWeight = null;
            protocolFwd.param_hangBuyPresaleResources.param.phaseEndTime = null;
            protocolFwd.param_hangBuyPresaleResources.param.minPickWeight = this.form.weight;
          }
          if (this.form.phaseType == "T" && this.form.tradeWay == "P") {
            protocolFwd.param_hangBuyPresaleResources.param.phaseStatus = "W";
          } else {
            protocolFwd.param_hangBuyPresaleResources.param.phaseStatus = null;
          }
          const that = this;
          this.isAddLoading = true;
          http
            .postFront(protocolFwd.param_hangBuyPresaleResources)
            .then(response => {
              const { code, message, value } = response.data;
              this.isAddLoading = false;
              if (code == 0) {
                if (that.form.phaseType == "T") {
                  that.sureStepPrice(value);
                  return;
                }
                this.dialogVisible = false;
                this.dialogVisible1 = false;
                this.$EL_MESSAGE("提交成功！待后台审核成功后方可发布");
                this.$refs["form"].resetFields();
                this.$emit("reLoad");
              } else {
                this.$EL_MESSAGE(message);
              }
            })
            .catch(() => {
              this.isAddLoading = false;
            });
        }
      });
    },
    handleClose() {
      this.form = {
        bargainingStatus: null, //是否可以议价T：可以F：不可以
        businessName: null, //业务负责人
        businessPhone: null, // 电话
        commodityId: null, // 商品id
        specificationsName: [], //规格名称{颜色：红色}json形式
        manufacturer: null, //厂家
        openTicketTime: null, // 开票期限
        phaseEndTime: null, //集采分销结束时间
        phaseType: null, //是否开启集采分销T：开F：不开
        phaseWeight: null, //集采分销达到重量
        price: null, //单价
        quantity: null, //挂单数量
        sourceId: null, //来源W：现货J：竞价C:预售
        tradeWay: null, // A：整批P：分销
        weight: null, // 挂单重量
        weightUnitId: null, // 重量单位
        putWarehousTime: null,
        image: null,
        storageId: null,
        effectiveTime: null,
        minPickWeight: null
        // image: "", // 商品图片
      };
      this.lowWeight = null;
      this.hightWeight = null;
      this.stepPrice = null;
      this.stepPriceList = [];
      this.centerDialogVisible = false;
      this.selectWeightUnit = {};
      //  this.newForm = {
      //   code: null,
      //   marginPassWord: null
      // }
      this.setAllPriceList = [{ lowWeight: "0", highWeight: "", price: "" }];
      this.$nextTick(() => {
        this.$refs["form"].clearValidate();
        // this.$refs["newForm"].clearValidate();
      });
      this.weightUnitOptions = [];
      this.dialogVisible = false;
      this.dialogVisible1 = false;
    }
  }
};
</script>
<style lang="scss" scoped>
/deep/ #d1 .el-dialog__title {
  color: red;
  font-size: 18px;
  font-weight: 700;
}
.tip {
  color: #cf4640;
  position: absolute;
  top: 9px;
  left: 66px;
}
.tips {
  color: #cf4640;
  position: absolute;
  top: 50px;
  left: 55px;
}
.stepPriceCon {
  line-height: 30px;
}
.stepPriceBtn {
  width: 80px;
  height: 30px;
  line-height: 30px;
  margin: 10px 30px;
  text-align: center;
  border: 1px solid #66b1ff;
  background-color: #66b1ff;
  color: #fff;
}
</style>
