<template>
  <div class="subPage">
    <div class="subPageTitle">
      <div class="subPageTitleText">
        <p>现货采购委托</p>
      </div>
      <div class="addButton">
        <!-- v-if="right.add" -->
        <el-button v-if="right.add" type="danger" @click="showAddDialog()"
          >新增现货采购</el-button
        >
      </div>
    </div>
    <div class="searchList">
      <el-row :gutter="10">
        <el-col :span="7">
          <el-col :span="6"><p class="searchItemTitle">商品分类</p></el-col>
          <el-col :span="18">
            <el-cascader
              class="width-100"
              v-model="selectCategoryOptions"
              :options="categoryOptions"
              :props="categoryProps"
              clearable
            ></el-cascader>
          </el-col>
        </el-col>
        <!-- <el-col :span="4">
          <el-col :span="6">
            <p class="searchItemTitle">仓库</p>
          </el-col>
          <el-col :span="18">
            <el-input v-model="searchForm.warehouse" placeholder="请输入仓库"></el-input>
          </el-col>
        </el-col> -->
        <el-col :span="4"></el-col>
        <el-col :span="6">
          <el-button type="danger" @click="getDatas()">搜索</el-button>
          <el-button @click="resetSearch()">重置</el-button>
        </el-col>
      </el-row>
    </div>
    <el-tabs class="tabList" v-model="activeTab" @tab-click="changeTab">
      <el-tab-pane label="全部" name="all"></el-tab-pane>
      <el-tab-pane label="已上架" name="pass"></el-tab-pane>
      <el-tab-pane label="待上架" name="fail"></el-tab-pane>
      <el-tab-pane label="已撤销" name="cancle"></el-tab-pane>
      <el-tab-pane label="未通过" name="noPass"></el-tab-pane>
    </el-tabs>
    <div class="tableList">
      <el-table :data="tableDatas">
        <el-table-column prop="className" label="商品分类"></el-table-column>
        <el-table-column prop="specificationsName" label="商品属性">
          <template slot-scope="scope">
            <span>{{
              scope.row.specificationsName | firstAttributeTransformate
            }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="price" label="单价">
          <template slot-scope="scope">
            <span
              >{{ scope.row.price }}元/{{
                scope.row.unit ? scope.row.unit : "--"
              }}</span
            >
          </template>
        </el-table-column>
        <el-table-column prop="hangWeight" label="重量">
          <template slot-scope="scope">
            <span>{{ scope.row.hangWeight }}{{ scope.row.unit }}</span>
          </template>
        </el-table-column>
        <!-- <el-table-column prop="gpPrice" label="总价"></el-table-column> -->
        <el-table-column prop="name" label="是否可议价">
          <template slot-scope="scope">
            <span>{{ scope.row.bargainingStatus | isholdFormate }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="auditStatus" label="资源状态">
          <template slot-scope="scope">
            <span>{{ scope.row.auditStatus | auditStatusFormate }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="hangResourcesStatus" label="挂单状态">
          <template slot-scope="scope">
            <span>{{ scope.row.hangResourcesStatus | hangStatusFormate }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="180">
          <template slot-scope="scope">
            <el-button @click="showViewDialog(scope.row)" size="mini"
              >查看</el-button
            >

            <el-button
              v-if="right.cancel"
              :disabled="
                scope.row.hangResourcesStatus == 'U' ||
                  scope.row.auditStatus == 'F'
              "
              @click="cancelResource(scope.row)"
              type="danger"
              size="mini"
              >撤销</el-button
            >
            <el-button
              style="margin-top:5px;"
              v-if="
                right.hang &&
                  scope.row.auditStatus == 'Y' &&
                  scope.row.hangResourcesStatus != 'U'
              "
              @click="payDialog(scope.row)"
              size="mini"
              >支付履约订金</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pagination">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="current"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="totalPage"
      ></el-pagination>
    </div>
    <!-- 查看 -->
    <viewDialog ref="viewDialog" />
    <!-- 新增 -->
    <addDialog ref="addDialog" @reLoad="getDatas()" />
    <!-- 修改 -->
    <editDialog ref="editDialog" @reLoad="getDatas()" />
    <!-- 支付履约订金 -->
    <payDialog ref="payDialog" @reLoad="getDatas()" />
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import viewDialog from "./viewDialog";
import addDialog from "./addDialog";
import editDialog from "./editDialog";
import payDialog from "./payDialog";
export default {
  data() {
    return {
      activeTab: "all",
      current: 1,
      pageSize: 10,
      totalPage: null,
      tableDatas: [],
      searchForm: {
        commodityName: null,
        warehouse: null,
        statusStr: null,
        orderStatus: null
      },
      right: {
        add: false,
        cancel: false,
        hang: false
      },
      selectCategoryOptions: [],
      categoryProps: {
        label: "className",
        value: "classId",
        checkStrictly: false
      },
      categoryOptions: []
    };
  },
  computed: {
    ...mapGetters(["sessionInfoGetter"])
  },
  components: {
    addDialog,
    editDialog,
    viewDialog,
    payDialog
  },
  mounted() {
    this.getDatas();
    this.getCommodityCategory();
    this.getRights();
  },
  methods: {
    getRights() {
      this.loadRight().then(loadResult => {
        if (loadResult == "loadRightSuccess") {
          this.right.add = this.isHasRight("tc-trade-hangBuyPresaleResources");
          this.right.cancel = this.isHasRight("tc-trade-undoHangResources");
          this.right.hang = this.isHasRight("tc-trade-payHangOrder");
        }
      });
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getDatas();
    },
    handleCurrentChange(val) {
      this.current = val;
      this.getDatas();
    },
    resetSearch() {
      this.searchForm = {
        commodityName: null,
        warehouse: null,
        statusStr: null,
        orderStatus: null
      };
      this.selectCategoryOptions = [];
      this.getDatas();
    },
    payDialog(row) {
      this.$refs.payDialog.showDialog(row);
    },
    changeTab(tab) {
      // 已上架（已通过，未撤销） statusStr 审核状态  Y：已提交 p:待审核T：已通过F：未通过  orderStatus订单状态O:已挂单Z：已摘单T：已完成U：已撤单
      // 待上架（已提交未支付，未撤销；已支付待审核，未撤销）
      // 已撤销（已提交未支付，已撤销；已支付待审核，已撤销；已通过，已撤销；未通过，已撤销）
      // 未通过（未通过，未撤销）
      this.activeTab = tab.name;
      if (this.activeTab === "all") {
        this.searchForm.statusStr = null;
        this.searchForm.orderStatus = null;
      } else if (this.activeTab === "pass") {
        this.searchForm.statusStr = ["T"];
        this.searchForm.orderStatus = ["O", "B", "A"];
      } else if (this.activeTab === "fail") {
        this.searchForm.statusStr = ["Y", "P"];
        this.searchForm.orderStatus = ["O", "B", "A"];
      } else if (this.activeTab === "cancle") {
        this.searchForm.statusStr = null;
        this.searchForm.orderStatus = ["U"];
      } else if (this.activeTab === "noPass") {
        this.searchForm.statusStr = ["F"];
        this.searchForm.orderStatus = ["O", "B", "A"];
      }
      this.getDatas();
    },
    getCommodityCategory() {
      http.getRes(protocolFwd.param_queryVarieties).then(response => {
        const { code, message, value } = response.data;
        if (code == 0) {
          let categoryArr = value;
          this.categoryOptions = this.clearChildren(categoryArr);
        } else {
          this.categoryOptions = [];
          this.$EL_MESSAGE(message);
        }
      });
    },
    clearChildren(Arr) {
      Arr.forEach(element => {
        if (element && element.children) {
          if (element.children.length == 0) {
            delete element["children"];
          } else {
            this.clearChildren(element.children);
          }
        }
      });
      return Arr;
    },
    getDatas() {
      protocolFwd.param_queryFirmOrderBeforeConfirm.param = {
        page: this.current - 1,
        size: this.pageSize,
        sort: [{ property: "hangCreateTime", direction: "DESC" }],
        className: this.searchForm.commodityName,
        hangFirmId: sessionStorage.getItem("userFirmId"),
        sourceId: "W",
        hangResourceType: ["Q"],
        commodityId:
          this.selectCategoryOptions.length > 0
            ? this.selectCategoryOptions[this.selectCategoryOptions.length - 1]
            : null,
        auditStatus: this.searchForm.statusStr,
        hangResourcesStatus: this.searchForm.orderStatus
        // warehouse: this.searchForm.warehouse,
        // statusStr: this.searchForm.statusStr,
        // orderStatus: this.searchForm.orderStatus
      };
      http
        .postFront(protocolFwd.param_queryFirmOrderBeforeConfirm)
        .then(response => {
          const { code, message, value } = response.data;
          if (code == 0) {
            // if (value.content) {
            //   this.tableDatas = value.content;
            // }
            // if (value.totalElements) {
            //   this.totalPage = value.totalElements;
            // }
          } else {
            this.$EL_MESSAGE(message);
          }
        });
    },
    showAddDialog() {
      this.$refs.addDialog.showDialog();
    },
    showEditDialog(row) {
      this.$refs.editDialog.showDialog(row);
    },
    showViewDialog(row) {
      this.$refs.viewDialog.showDialog(row);
    },
    cancelResource(row) {
      this.$confirm("请确认是否撤销该现货销售？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          protocolFwd.param_undoHangResources.param.hangResourcesId =
            row.hangResourcesId;
          http.postFront(protocolFwd.param_undoHangResources).then(response => {
            const { code, message, value } = response.data;
            if (code == 0) {
              this.$EL_MESSAGE(message);
              this.getDatas();
            } else {
              this.$EL_MESSAGE(message);
            }
          });
        })
        .catch(() => {});
    }
  }
};
</script>
<style scoped lang="scss">
.subPage {
  padding: 0 15px;
}
.subPageTitle {
  overflow: hidden;
}
.subPageTitle .subPageTitleText {
  float: left;
  line-height: 40px;
}
.subPageTitle .subPageTitleText p {
  font-size: 16px;
}
.subPageTitle .addButton {
  float: right;
}
.searchList {
  margin: 10px 0;
}
.tabList {
  margin-top: 20px;
}
.tableList {
  margin-top: 20px;
}
.tableList {
  margin: 10px 0;
  /deep/ .el-table thead th {
    background-color: rgba(5, 64, 106, 1);
    color: #fff;
  }
  .allSelectBox {
    line-height: 30px;
    margin-left: 20px;
  }
}
.subPage .pagination {
  margin-top: 15px;
}
.searchItemTitle {
  line-height: 40px;
}
</style>
